import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface EditOrCreatePlayerContractRequest {
    name: string;
    relation: any;
    phoneNumber: string;
    nationalNumber: string;
}

interface EditOrCreatePlayerContract {
    message: string;
    status: number;
    payload: any;
}

const createPlayerContract = async (
    id: string,
    data: EditOrCreatePlayerContractRequest,
): Promise<EditOrCreatePlayerContract> => {
    return api.url(`players/${id}/emergency-contact`).post(data).json<EditOrCreatePlayerContract>();
};

const editPlayerContract = async (
    id: string,
    data: EditOrCreatePlayerContractRequest,
): Promise<EditOrCreatePlayerContract> => {
    return api
        .url(`players/${id}/emergency-contact`)
        .patch(data)
        .json<EditOrCreatePlayerContract>();
};

export const useCreatePlayerContract = (
    id: string,
    options?: UseMutationOptions<
        EditOrCreatePlayerContract,
        Error,
        EditOrCreatePlayerContractRequest
    >,
): UseMutationResult<EditOrCreatePlayerContract, Error, EditOrCreatePlayerContractRequest> => {
    return useMutation<EditOrCreatePlayerContract, Error, EditOrCreatePlayerContractRequest>({
        mutationFn: (data) => createPlayerContract(id, data),
        ...options,
    });
};

export const useEditPlayerContract = (
    id: string,
    options?: UseMutationOptions<
        EditOrCreatePlayerContract,
        Error,
        EditOrCreatePlayerContractRequest
    >,
): UseMutationResult<EditOrCreatePlayerContract, Error, EditOrCreatePlayerContractRequest> => {
    return useMutation<EditOrCreatePlayerContract, Error, EditOrCreatePlayerContractRequest>({
        mutationFn: (data) => editPlayerContract(id, data),
        ...options,
    });
};
