import { useLocales } from 'hooks/locales';
import * as yup from 'yup';

export const useSendMessageSchema = () => {
    const { trans } = useLocales();

    const sendMessageValidators = {
        email: yup
            .string()
            .required(trans('validation.sendMessage.emailRequired'))
            .matches(
                /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+\.[a-zA-Z]{2,4}$/,
                trans('validation.sendMessage.emailInvalid'),
            )
            .default(''),
        name: yup.string().required(trans('validation.sendMessage.nameRequired')).default(''),
        notes: yup.string().required(trans('validation.sendMessage.notesRequired')).default(''),
        avatar: yup.string().required(trans('validation.sendMessage.notesRequired')).default(''),
        type: yup.string().required(trans('validation.sendMessage.notesRequired')).default(''),
    };

    const SEND_MESSAGE_DEFAULTS = {
        athletes: [],
    };

    return yup.object().shape(sendMessageValidators);
};
