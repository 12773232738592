import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface EditAdminStratorsContactRequest {
    name: string;
    relation: any;
    phoneNumber: string;
    nationalNumber: string;
}

interface EditAdminStratorsContact {
    message: string;
    status: number;
    payload: any;
}

const createAdminStratorsContract = async (
    id: string,
    data: EditAdminStratorsContactRequest,
): Promise<EditAdminStratorsContact> => {
    return api.url(`players/${id}/emergency-contact`).post(data).json<EditAdminStratorsContact>();
};

const editAdminStratorsContract = async (
    id: string,
    data: EditAdminStratorsContactRequest,
): Promise<EditAdminStratorsContact> => {
    return api.url(`players/${id}/emergency-contact`).patch(data).json<EditAdminStratorsContact>();
};

export const useCreateAdminStratorsContract = (
    id: string,
    options?: UseMutationOptions<EditAdminStratorsContact, Error, EditAdminStratorsContactRequest>,
): UseMutationResult<EditAdminStratorsContact, Error, EditAdminStratorsContactRequest> => {
    return useMutation<EditAdminStratorsContact, Error, EditAdminStratorsContactRequest>({
        mutationFn: (data) => createAdminStratorsContract(id, data),
        ...options,
    });
};

export const useEditAdminStratorsContract = (
    id: string,
    options?: UseMutationOptions<EditAdminStratorsContact, Error, EditAdminStratorsContactRequest>,
): UseMutationResult<EditAdminStratorsContact, Error, EditAdminStratorsContactRequest> => {
    return useMutation<EditAdminStratorsContact, Error, EditAdminStratorsContactRequest>({
        mutationFn: (data) => editAdminStratorsContract(id, data),
        ...options,
    });
};
