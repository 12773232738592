import React, { useState } from 'react';
import * as Theme from './Theme';
import { useFormContext, Controller } from 'react-hook-form';
import { useLocales } from 'hooks/locales';
import { LabelInput } from '../../labelInput';
import ReactDatePicker from 'react-datepicker';
import { EmploymentType, ClubRole, MaritalStatus } from 'libs/enums';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { addMonths, arrayToSelectOptions } from 'libs/helpers';
import { InputDateController } from 'components/inputDate';
import { InputController } from 'components/input';

export const FileInformation: React.FC = () => {
    const { trans } = useLocales();
    const {
        control,
        formState: { errors },
    } = useFormContext();
    console.log(errors);
    return (
        <Theme.Body>
            <Theme.GridWrapper>
                {/* Position */}
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.position')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'position',
                                    options: arrayToSelectOptions({ array: ClubRole }),
                                    transSuffix: 'form.add.manager.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.relationship')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'relationship',
                                    options: arrayToSelectOptions({ array: MaritalStatus }),
                                    transSuffix: 'form.editAthleteProfile.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.InputsWrapper>
                {/* Branch ID */}
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.phone')} />
                    <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                            <>
                                <InputController
                                    {...field}
                                    control={control}
                                    placeholder={trans('placeholder.phone')}
                                />
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.emergencyPhone')} />
                    <Controller
                        control={control}
                        name="emergencyPhone"
                        render={({ field }) => (
                            <>
                                <InputController
                                    {...field}
                                    control={control}
                                    placeholder={trans('placeholder.emergencyPhone')}
                                />
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
                {/* Contract Duration */}
                <Theme.InputsWrapper>
                    <LabelInput label={trans('label.contractDuration')} />
                    <Controller
                        control={control}
                        name="contractDuration"
                        render={({ field }) => (
                            <>
                                <InputController
                                    {...field}
                                    control={control}
                                    placeholder={trans('placeholder.contractDuration')}
                                />
                            </>
                        )}
                    />
                </Theme.InputsWrapper>
                {/* Joined Date */}
                <Theme.FullWidthInputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.joinedDate')}
                        content={
                            <InputDateController
                                control={control}
                                name="joinDate"
                                placeholder={trans('placeholder.joinedDate')}
                            />
                        }
                    />
                </Theme.FullWidthInputsWrapper>

                {/* Contract Start Date */}
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.contractStartDate')}
                        content={
                            <InputDateController
                                control={control}
                                name="contractStartDate"
                                placeholder={trans('placeholder.contractStartDate')}
                            />
                        }
                    />
                </Theme.InputsWrapper>

                {/* Contract End Date */}
                <Theme.InputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.contractEndDate')}
                        content={
                            <InputDateController
                                control={control}
                                name="contractEndDate"
                                placeholder={trans('placeholder.contractEndDate')}
                                maxDate={addMonths(new Date(), 100)}
                                minDate={new Date()}
                            />
                        }
                    />
                </Theme.InputsWrapper>

                {/* Contract Renewal Terms */}
                <Theme.FullWidthInputsWrapper>
                    <FormRow
                        style={{
                            fontSize: '14px',
                            color: '#777777',
                            display: 'block',
                        }}
                        title={trans('label.contractType')}
                        content={
                            <MultiSelectController
                                {...{
                                    control,
                                    name: 'contractType',
                                    options: arrayToSelectOptions({ array: EmploymentType }),
                                    transSuffix: 'select.option.',
                                    menuPlacement: 'bottom',
                                }}
                            />
                        }
                    />
                </Theme.FullWidthInputsWrapper>
                {/* Contract Benefits */}
                <Theme.FullWidthInputsWrapper>
                    <LabelInput label={trans('label.contractBenefits')} />
                    <Controller
                        control={control}
                        name="contractBenefits"
                        render={({ field }) => (
                            <>
                                <InputController
                                    {...field}
                                    control={control}
                                    placeholder={trans('placeholder.contractBenefits')}
                                />
                            </>
                        )}
                    />
                </Theme.FullWidthInputsWrapper>
            </Theme.GridWrapper>
        </Theme.Body>
    );
};
