import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSendMessageSchema } from 'schemas';
import { FilePickerController, InputController, RadioButton } from 'components';
import { academyAPIs } from 'services/apis';
import { fireAlert } from 'libs/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { FormRow } from 'components/modal-windows/FormRow';
import { RadioGroupInput } from 'components/RadioGroupInput';
import { SharedButton } from 'components/sharedButton';
import { useState } from 'react';
import { router } from 'routers';
import { StoreState } from 'libs/types';

export const ContactUs = () => {
    const user = useSelector((state: StoreState) => state.auth?.entities);
    const { trans } = useLocales();
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const { academy } = useSelector(selectAcademy);
    const sendMessageSchema = useSendMessageSchema();
    const {
        formState: { isValid },
        control,
        handleSubmit,
        setValue,
    } = useForm<any>({
        mode: 'all',
        resolver: yupResolver(sendMessageSchema),
    });

    const handleSend = async (data: any) => {
        const response = await dispatch(
            academyAPIs.createFeedback()({ ...data, academy: academy?.id || '' }),
        );

        const isSuccess = [201, 200].includes(response?.payload?.status);

        if (isSuccess) {
            setIsSuccess(true);
            fireAlert(
                {
                    type: isSuccess ? 'success' : 'warning',
                    title: isSuccess ? 'Success' : 'Warning',
                    subtitle: isSuccess
                        ? 'Feedback has been sent successfully'
                        : response?.payload?.payload?.payload || 'Error Occurred',
                },
                dispatch,
            );

            setValue('name', '');
            setValue('notes', '');
            setValue('email', '');
        }
    };
    const handleBackHome = () => {
        router.navigate('home', { replace: true });
    };

    return (
        <>
            {!isSuccess ? (
                <Theme.Body onSubmit={handleSubmit(handleSend)}>
                    <Theme.TitleWrapper>
                        <Theme.TitlePara>{trans('text.welcome')},</Theme.TitlePara>
                        <Theme.TitleSpan>{user?.username}</Theme.TitleSpan>
                        <p>{`${trans('contactMessage')} {${user?.email}}.`}</p>
                        <Theme.FormWrapper>
                            <RadioGroupInput
                                label={trans('label.type')}
                                name="type"
                                control={control}
                                options={[
                                    { value: 'issue', label: trans('label.issue') },
                                    { value: 'request', label: trans('label.request') },
                                    { value: 'suggestion', label: trans('label.suggestion') },
                                    { value: 'other', label: trans('label.other') },
                                ]}
                            />
                            <InputController
                                {...{
                                    control,
                                    name: 'subject',
                                    label: trans('home.contacts.subject'),
                                    placeholder: trans('home.contacts.subject.placeholder'),
                                }}
                            />
                            <InputController
                                {...{
                                    control,
                                    name: 'notes',
                                    label: trans('home.contacts.message'),
                                    placeholder: trans('home.contacts.message.placeholder'),
                                }}
                                isMultiline={true}
                            />

                            <FormRow
                                style={{
                                    fontSize: '14px',
                                    color: '#777777',
                                    display: 'block',
                                }}
                                title={trans('home.contacts.media')}
                                content={
                                    <FilePickerController
                                        {...{
                                            control,
                                            name: 'avatar',
                                            placeholder: trans('home.contacts.media'),
                                            accept: 'image/*',
                                            maxFiles: 1,
                                        }}
                                    />
                                }
                            />
                        </Theme.FormWrapper>
                        <Theme.ButtonWrapper>
                            <SharedButton type="submit" onClick={handleSubmit(handleSend)}>
                                {trans('home.contacts.sendMessage')}
                            </SharedButton>
                        </Theme.ButtonWrapper>
                    </Theme.TitleWrapper>
                </Theme.Body>
            ) : (
                <Theme.SuccessSentWrapper>
                    <Theme.Image src="/assets/images/success-sent.svg" alt="sent email" />
                    <Theme.Logo src={'/assets/icons/logo.svg'} alt="logo" />
                    <Theme.HeadTitle>{trans('home.contacts.success.header')}</Theme.HeadTitle>
                    <Theme.SubTitle>{`${trans(
                        'home.contacts.success.subTitle',
                    )} {${user?.email}}.`}</Theme.SubTitle>
                    <SharedButton type="submit" onClick={handleBackHome}>
                        {trans('home.contacts.success.back')}
                    </SharedButton>
                </Theme.SuccessSentWrapper>
            )}
        </>
    );
};
