import styled from 'styled-components';

export const Body = styled.div`
    min-height: 100%;
    width: 17px;
    display: flex;
    align-items: center;
    background-image: url('/assets/images/signup-frame.png');
    background-repeat: repeat-y;
    background-position: center;
    background-size: contain;
`;
