import React, { ReactNode } from 'react';
import * as Theme from './Theme';

interface SharedButtonProps {
    onClick: () => void;
    disabled?: boolean;
    icon?: React.ElementType;
    children: ReactNode;
    variant?: 'primary' | 'secondary';
    type?: string;
}

export const SharedButton: React.FC<SharedButtonProps> = ({
    onClick,
    disabled,
    icon: Icon,
    children,
    variant = 'primary',
}) => {
    return (
        <Theme.SharedButton type="button" onClick={onClick} disabled={disabled} variant={variant}>
            {Icon && <Icon />}
            {children}
        </Theme.SharedButton>
    );
};
