export enum FootballPosition {
    STRIKER = 'striker',
    RIGHT_WING = 'rightWing',
    LEFT_WING = 'leftWing',
    DEFENDER = 'defender',
    GOALKEEPER = 'goalkeeper',
}

export enum SwimmingDistance {
    SHORT_DISTANCE = 'shortDistance',
    MID_DISTANCE = 'midDistance',
    LONG_DISTANCE = 'longDistance',
}

export enum KarateBelt {
    WHITE_BELT = 'whiteBelt',
    YELLOW_BELT = 'yellowBelt',
    GREEN_BELT = 'greenBelt',
    ORANGE_BELT = 'orangeBelt',
    BLUE_BELT = 'blueBelt',
    BROWN_BELT = 'brownBelt',
    BLACK_BELT = 'blackBelt',
}
export enum AthleticsEvent {
    SPRINT_100M = 'sprint100m',
    SPRINT_200M = 'sprint200m',
    SPRINT_400M = 'sprint400m',
    MIDDLE_DISTANCE_800M = 'middleDistance800m',
    MIDDLE_DISTANCE_1500M = 'middleDistance1500m',
    LONG_DISTANCE_5000M = 'longDistance5000m',
    LONG_DISTANCE_10000M = 'longDistance10000m',
    HURDLES_100M = 'hurdles100m',
    HURDLES_110M = 'hurdles110m',
    HURDLES_400M = 'hurdles400m',
    RELAYS_4X100M = 'relays4x100m',
    RELAYS_4X400M = 'relays4x400m',
    STEEPLECHASE_3000M = 'steeplechase3000m',
    JUMPS_LONG = 'jumpsLong',
    JUMPS_TRIPLE = 'jumpsTriple',
    JUMPS_HIGH = 'jumpsHigh',
    JUMPS_POLE_VAULT = 'jumpsPoleVault',
    THROWS_SHOT_PUT = 'throwsShotPut',
    THROWS_DISCUS = 'throwsDiscus',
    THROWS_JAVELIN = 'throwsJavelin',
    THROWS_HAMMER = 'throwsHammer',
    HEPTATHLON_7 = 'heptathlon7',
    DECATHLON_10 = 'decathlon10',
}

export enum TaekwondoBelt {
    WHITE_BELT_STRIKER = 'whiteBeltStriker',
    WHITE_BELT_YELLOW_TAG_STRIKER = 'whiteBeltYellowTagStriker',
    WHITE_BELT_DOUBLE_YELLOW_TAG_STRIKER = 'whiteBeltDoubleYellowTagStriker',
    YELLOW_BELT_BLUE_TAG_STRIKER = 'yellowBeltBlueTagStriker',
    ORANGE_BELT_STRIKER = 'orangeBeltStriker',
    GREEN_BELT_STRIKER = 'greenBeltStriker',
    PURPLE_BELT_STRIKER = 'purpleBeltStriker',
    BLUE_BELT_STRIKER = 'blueBeltStriker',
    BLUE_BELT_RED_TAG_STRIKER = 'blueBeltRedTagStriker',
    BROWN_BELT_STRIKER = 'brownBeltStriker',
    RED_BELT_STRIKER = 'redBeltStriker',
    RED_BELT_BLACK_TAG_STRIKER = 'redBeltBlackTagStriker',
    BLACK_BELT = 'blackBelt',
}

export enum JudoBelt {
    WHITE_BELT = 'whiteBelt',
    YELLOW_BELT = 'yellowBelt',
    ORANGE_BELT = 'orangeBelt',
    GREEN_BELT = 'greenBelt',
    BLUE_BELT = 'blueBelt',
    BROWN_BELT = 'brownBelt',
    BLACK_BELT = 'blackBelt',
}

export enum JujutsuBelt {
    WHITE_BELT = 'whiteBelt',
    PURPLE_BELT = 'purpleBelt',
    CORAL_BELT = 'coralBelt',
    RED_BELT = 'redBelt',
    BLUE_BELT = 'blueBelt',
    BROWN_BELT = 'brownBelt',
    BLACK_BELT = 'blackBelt',
}

export enum FutsalPosition {
    STRIKER = 'striker',
    LEFT_WING = 'leftWing',
    RIGHT_WING = 'rightWing',
    DEFENDER = 'defender',
    GOALKEEPER = 'goalkeeper',
}

export enum BeachVolleyballEvent {
    DEFAULT_DOUBLE = 'defaultDouble',
}

export enum PadelEvent {
    DEFAULT_DOUBLE = 'defaultDouble',
}

export enum TriathlonEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}

export enum BalootEvent {
    DEFAULT_DOUBLE = 'defaultDouble',
}

export enum WrestlingEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}

export enum MuayThaiEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}

export enum BoxingEvent {
    DEFAULT_SINGLE = 'defaultSingle',
}
export enum GymnasticsEvent {
    FLOOR_EXERCISE = 'floorExercise',
    POMMEL_HORSE = 'pommelHorse',
    STILL_RINGS = 'stillRings',
    VAULT = 'vault',
    PARALLEL_BARS = 'parallelBars',
    HORIZONTAL_BAR = 'horizontalBar',
    UNEVEN_BARS = 'unevenBars',
    BALANCE_BEAM = 'balanceBeam',
    ALL_AROUND_INDIVIDUAL = 'allAroundIndividual',
    ALL_AROUND_TEAM = 'allAroundTeam',
    RHYTHMIC_ROPE = 'rhythmicRope',
    RHYTHMIC_HOOP = 'rhythmicHoop',
    RHYTHMIC_BALL = 'rhythmicBall',
    RHYTHMIC_CLUBS = 'rhythmicClubs',
    RHYTHMIC_RIBBON = 'rhythmicRibbon',
    RHYTHMIC_ALL_GROUPED = 'rhythmicAllGrouped',
    TRAMPOLINE_INDIVIDUAL = 'trampolineIndividual',
    TRAMPOLINE_SYNCHRONIZED = 'trampolineSynchronized',
    TRAMPOLINE_DOUBLE_MINI = 'trampolineDoubleMini',
    TRAMPOLINE_TUMBLING = 'trampolineTumbling',
    ACROBATIC_PAIR = 'acrobaticPair',
    ACROBATIC_GROUP = 'acrobaticGroup',
}
export enum FencingEvent {
    FOIL_INDIVIDUAL = 'foilIndividual',
    SABRE_INDIVIDUAL = 'sabreIndividual',
    EPEE_INDIVIDUAL = 'epeeIndividual',
}
export enum ArcheryEvent {
    INDIVIDUAL_COMPOUND = 'individualCompound',
    INDIVIDUAL_RECURVE = 'individualRecurve',
}
export enum EquestrianEvent {
    ENDURANCE_MIXED_TEAM = 'enduranceMixedTeam',
    JUMPING_INDIVIDUAL = 'jumpingIndividual',
    JUMPING_MIXED_TEAM = 'jumpingMixedTeam',
}
export enum BilliardSnookerEvent {
    BILLIARD_10_BALL = 'billiard10Ball',
    BILLIARD_9_BALL = 'billiard9Ball',
    SNOOKER_15_RED_BALL = 'snooker15RedBall',
}
export enum BadmintonEvent {
    SINGLE = 'single',
    DOUBLE = 'double',
}
export enum ChessEvent {
    RAPID = 'rapid',
    CLASSIC = 'classic',
}
export enum BowlingEvent {
    SINGLE = 'single',
    DOUBLE = 'double',
    MIXED_DOUBLES = 'mixedDoubles',
    TRIOS = 'trios',
}
export enum BeachSoccerEvent {
    GOALKEEPER = 'goalkeeper',
    DEFENDER = 'defender',
    MIDFIELDER = 'midfielder',
    FORWARDS = 'forwards',
}
export enum SportClimbingEvent {
    LEAD_CLIMBING = 'leadClimbing',
    SPEED_CLIMBING = 'speedClimbing',
    BOULDERING = 'bouldering',
}
export enum ShootingEvent {
    AIR_PISTOL_10M = '10mAirPistol',
    AIR_RIFLE_10M = '10mAirRifle',
    RAPID_FIRE_PISTOL_25M = '25mRapidFirePistol',
    SHOTGUN = 'shotgun',
    SKEET_SHOOTING = 'skeetShooting',
    TRAP = 'trap',
}
export enum SkateboardingEvent {
    FREESTYLE = 'freestyle',
    DOWNHILL_SLIDE = 'downhillSlide',
    LONGBOARD = 'longboard',
    STREET_SKATEBOARD = 'streetSkateboard',
}
export enum Basketball3x3Event {
    POINT_GUARD = 'pointGuard',
    SHOOTING_GUARD = 'shootingGuard',
    SMALL_FORWARD = 'smallForward',
    POWER_FORWARD = 'powerForward',
    CENTRE_POST = 'centrePost',
}
export enum SquashEvent {
    SINGLE = 'single',
    DOUBLE = 'double',
}
export enum EsportsEvent {
    GAME_NAME = 'gameName',
}
export enum PingPongEvent {
    SINGLE = 'single',
    DOUBLE = 'double',
}
export enum TennisEvent {
    SINGLE = 'single',
    DOUBLE = 'double',
}
export enum HandballEvent {
    GOALKEEPER = 'goalkeeper',
    LEFT_WING = 'leftWing',
    LEFT_BACK = 'leftBack',
    CENTRE_BACK = 'centreBack',
    PIVOT = 'pivot',
    RIGHT_BACK = 'rightBack',
    RIGHT_WING = 'rightWing',
}
export enum VolleyballEvent {
    SETTER = 'setter',
    LIBERO = 'libero',
    OUTSIDE_HITTER = 'outsideHitter',
    OPPOSITE_HITTER = 'oppositeHitter',
    MIDDLE_BLOCKER_HITTER = 'middleBlockerHitter',
    DEFENSIVE_SPECIALIST = 'defensiveSpecialist',
    SERVING_SPECIALIST = 'servingSpecialist',
}
export enum BasketballEvent {
    POINT_GUARD = 'pointGuard',
    SHOOTING_GUARD = 'shootingGuard',
    SMALL_FORWARD = 'smallForward',
    POWER_FORWARD = 'powerForwardPlayer',
    CENTRE_PLAYER = 'centrePlayer',
}
