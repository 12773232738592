import { useLocales } from 'hooks/locales';
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { useRouter } from 'react-router5';
import { TemplatesTableAdministrator } from 'components/templatesTableAdministrator';
import { useFetchAdministratorsTable } from 'services/hooks/administrator/useFetchAdministratorsTable';
import { Pagination } from 'components/paginationComponant/Pagination';
import { useBreadcrumbs } from 'hooks/breadcrumbs';

interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}

export const Administrator = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const { academy } = useSelector(selectAcademy);
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();

    // Fetch data using the custom hook
    const { data, isLoading, error } = useFetchAdministratorsTable(sportId, page, limit);

    // Set up columns dynamically based on translations
    useEffect(() => {
        setColumns([
            {
                key: 'administrator',
                label: trans('administrator.table.administrator'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'experience',
                label: trans('administrator.table.experience'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'type',
                label: trans('administrator.table.type'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'teamCategory',
                label: trans('administrator.table.teamCategory'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'contract',
                label: trans('administrator.table.contract'),
                width: '15%',
                sortable: true,
            },
            // Uncomment if needed
            // {
            //     key: 'contact',
            //     label: trans('administrator.table.contact'),
            //     width: '15%',
            //     sortable: true,
            // },
        ]);
    }, [trans]);

    console.log(data);

    // Handle page change
    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };
    const totalPages = Math.ceil((data?.payload?.meta?.totalItems || 0) / limit);
    console.log('data?.totalPages', data?.meta?.totalPages);
    // Handle errors
    if (error) return <div>Error: {error.message}</div>;
    useBreadcrumbs(
        [
            { label: trans('breadcrumbs.home'), path: 'home' },
            {
                label: trans('breadcrumbs.administrator'),
                path: 'administrator',
                params: { sportId },
            },
        ],
        trans,
    );
    return (
        <div style={{ width: '100%' }}>
            <TemplatesTableAdministrator
                loading={isLoading}
                columns={columns}
                data={data?.payload?.items || []}
                total={data?.payload?.meta?.totalItems || 0}
            />
            <Pagination
                currentPage={data?.payload?.meta?.currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};
