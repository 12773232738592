import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { useFetchTeamsTable } from 'services/hooks/teams/useFetchTeamsList';
import { router } from 'routers';
import { TemplatesTableTeams } from '../../components/templatesTableTeams';
import { Pagination } from 'components/paginationComponant/Pagination';
import { useBreadcrumbs } from 'hooks/breadcrumbs';

interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}

export const Teams = () => {
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const { academy } = useSelector(selectAcademy);
    const {
        params: { sportId },
    } = router.getState();

    const { data, isLoading, error } = useFetchTeamsTable(sportId, page, limit);
    console.log(data);
    useEffect(() => {
        setColumns([
            {
                key: 'category',
                label: trans('teams.table.category'),
                width: '20%',
                sortable: false,
            },
            {
                key: 'nickname',
                label: trans('teams.table.nickname'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'size',
                label: trans('teams.table.size'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'positions',
                label: trans('teams.table.positions'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'coach',
                label: trans('teams.table.coach'),
                width: '15%',
                sortable: false,
            },
            {
                key: 'sub-coach',
                label: trans('teams.table.sub-coach'),
                width: '15%',
                sortable: true,
            },
            {
                key: 'next-session',
                label: trans('teams.table.next-session'),
                width: '15%',
                sortable: true,
            },
        ]);
    }, [trans]);
    console.log(data);
    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };
    const totalPages = Math.ceil((data?.payload?.meta?.totalItems || 0) / limit);

    if (error) return <div>Error: {error.message}</div>;
    useBreadcrumbs(
        [
            { label: trans('breadcrumbs.home'), path: 'home' },
            { label: trans('breadcrumbs.teams'), path: 'teams', params: { sportId } },
        ],
        trans,
    );
    return (
        <div style={{ width: '100%' }}>
            <TemplatesTableTeams
                loading={isLoading}
                columns={columns}
                data={data?.payload?.items || []}
                total={data?.payload?.meta?.totalItems || 0}
            />
            <Pagination
                currentPage={data?.payload?.meta?.currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};
