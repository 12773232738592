import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface EditOrCreateCoachContactRequest {
    name: string;
    relation: any;
    phoneNumber: string;
    nationalNumber: string;
}

interface EditOrCreateCoachContact {
    message: string;
    status: number;
    payload: any;
}

const createCoachContract = async (
    id: string,
    data: EditOrCreateCoachContactRequest,
): Promise<EditOrCreateCoachContact> => {
    return api.url(`players/${id}/emergency-contact`).post(data).json<EditOrCreateCoachContact>();
};

const editCoachContract = async (
    id: string,
    data: EditOrCreateCoachContactRequest,
): Promise<EditOrCreateCoachContact> => {
    return api.url(`players/${id}/emergency-contact`).patch(data).json<EditOrCreateCoachContact>();
};

export const useCreateCoachContract = (
    id: string,
    options?: UseMutationOptions<EditOrCreateCoachContact, Error, EditOrCreateCoachContactRequest>,
): UseMutationResult<EditOrCreateCoachContact, Error, EditOrCreateCoachContactRequest> => {
    return useMutation<EditOrCreateCoachContact, Error, EditOrCreateCoachContactRequest>({
        mutationFn: (data) => createCoachContract(id, data),
        ...options,
    });
};

export const useEditCoachContract = (
    id: string,
    options?: UseMutationOptions<EditOrCreateCoachContact, Error, EditOrCreateCoachContactRequest>,
): UseMutationResult<EditOrCreateCoachContact, Error, EditOrCreateCoachContactRequest> => {
    return useMutation<EditOrCreateCoachContact, Error, EditOrCreateCoachContactRequest>({
        mutationFn: (data) => editCoachContract(id, data),
        ...options,
    });
};
