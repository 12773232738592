import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';

interface RadioGroupProps extends UseControllerProps {
    label: string;
    options: { value: string; label: string }[];
}

export const RadioGroupInput: React.FC<RadioGroupProps> = ({ label, name, control, options }) => {
    const {
        field: { onChange, value },
    } = useController({ name, control });

    const Wrapper = styled.div`
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    `;

    const Label = styled.label`
        font-weight: 500;
        font-size: 14px;
        display: block;
        margin-bottom: 5px;
        color: #20240399;
    `;

    const OptionsContainer = styled.div`
        display: flex;
        gap: 15px;
    `;

    const Option = styled.label`
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 8px;
        input[type='radio'] {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    `;

    return (
        <Wrapper>
            <Label>{label}</Label>
            <OptionsContainer>
                {options.map((option) => (
                    <Option key={option.value}>
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={value?.toString?.() === option?.value?.toString?.()}
                            onChange={(e) => onChange(e.target.value)}
                        />
                        {option.label}
                    </Option>
                ))}
            </OptionsContainer>
        </Wrapper>
    );
};
