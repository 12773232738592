import React, { useState, useEffect } from 'react';
import { SharedModal } from '../sharedModal';
import { useLocales } from 'hooks/locales';
import * as Theme from './Theme';
import { useRouter } from 'react-router5';
import { useDispatch } from 'react-redux';
import { FormRow } from 'components/modal-windows/FormRow';
import { addMonths, arrayToSelectOptions } from 'libs/helpers';
import { setModalContent } from 'store/controlsSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputController } from 'components/input';
import { AddSubGoalRequest, useAddSubGoal } from 'services/hooks/goals/useSubGoal';
import { useForm } from 'react-hook-form';
import { useNewSubGoalSchema } from 'schemas/goals/subGoalValidationSchema';
import { MultiSelectController } from 'components/multi-selection';
import { Months, UnitsSessions } from 'libs/enums';
import { SharedButton } from 'components/sharedButton';
import { SaveLoaderButton } from 'components/saveLoaderButton';

interface SizeModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const SubGoalModal: React.FC<SizeModalProps> = ({ isOpen, onClose }) => {
    const { trans } = useLocales();
    const customHeight = '100%';
    const dispatch = useDispatch();
    const [isReordered, setIsReordered] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const router = useRouter();
    const {
        params: { sportId },
    } = router.getState();

    const handleModalClose = () => {
        if (isReordered) {
            setShowWarning(true);
        } else {
            onClose();
            reset();
        }
    };
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const subGoalSchema = useNewSubGoalSchema();
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(subGoalSchema),
    });
    const { mutateAsync } = useAddSubGoal(sportId);

    const handleSave = async (data: any) => {
        setIsSubmittingForm(true);
        const payload = {
            description: data.description,
            months: data.months.value,
            missionOne: data.missionOne,
            unitNumber: data.unitNumber,
            unit: data.unit.value,
            sessionsNeeded: data.sessionsNeeded,
            missionTwo: data.missionTwo,
            unitNumberTwo: data.unitNumberTwo,
            unitTwo: data.unitTwo.value,
            sessionsNeededTwo: data.sessionsNeededTwo,
            missionThree: data.missionThree,
            unitNumberThree: data.unitNumberThree,
            unitThree: data.unitThree.value,
            sessionsNeededThree: data.sessionsNeededThree,
            missionFour: data.missionFour,
            unitNumberFour: data.unitNumberFour,
            unitFour: data.unitFour.value,
            sessionsNeededFour: data.sessionsNeededFour,
        } as AddSubGoalRequest;
        try {
            const response = await mutateAsync(payload);
            if ([200, 201].includes(response.status)) {
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'success',
                            title: trans('form.success'),
                            subtitle: trans('subGoal.created_successfully'),
                        },
                    }),
                );
                // refetch();
                onClose();
                reset();
            } else {
                const errorMessage = response.message || trans('form.error_occurred');
                dispatch(
                    setModalContent({
                        modalContent: {
                            type: 'warning',
                            title: trans('form.warning'),
                            subtitle: errorMessage,
                        },
                    }),
                );
            }
        } catch (error: any) {
            onClose();
            console.error('Error occurred while saving subGoal:', error);
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'warning',
                        title: trans('form.warning'),
                        subtitle: trans(error),
                    },
                }),
            );
        } finally {
            setIsSubmittingForm(false);
        }
    };

    return (
        <>
            <SharedModal
                isOpen={isOpen}
                customHeight={customHeight}
                onRequestClose={handleModalClose}
                title={`${trans('add.subGoal')}`}
            >
                <Theme.LineHR />
                <Theme.Body>
                    <Theme.FullWidthInputsWrapperTwoInputs>
                        <FormRow
                            style={{
                                fontSize: '14px',
                                color: '#777777',
                                display: 'block',
                            }}
                            title={trans('label.months')}
                            content={
                                <MultiSelectController
                                    style={{
                                        borderRadius: '8px',
                                    }}
                                    {...{
                                        control,
                                        name: 'months',
                                        options: arrayToSelectOptions({ array: Months }),
                                        transSuffix: 'form.subGoal.',
                                        menuPlacement: 'bottom',
                                    }}
                                />
                            }
                        />
                        <FormRow
                            style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                            title={trans('add.sub.goal')}
                            content={
                                <InputController
                                    type="text"
                                    {...{
                                        control,
                                        name: 'description',
                                        placeholder: trans('home.branchesList.description'),
                                    }}
                                />
                            }
                        />
                    </Theme.FullWidthInputsWrapperTwoInputs>
                    <Theme.FullWidthInputsWrapperTwoInputs>
                        <Theme.InputsWrapper>
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.missionOne')}
                                content={
                                    <InputController
                                        type="text"
                                        {...{
                                            control,
                                            name: 'missionOne',
                                            placeholder: trans('teams.add.missionOne'),
                                        }}
                                    />
                                }
                            />
                        </Theme.InputsWrapper>
                        <Theme.TwoInputsWrapper>
                            <FormRow
                                style={{
                                    fontSize: '14px',
                                    color: '#777777',
                                    display: 'block',
                                }}
                                contentStyle={{
                                    display: 'flex',
                                    gap: '4px',
                                }}
                                title={trans('label.volume.targeted')}
                                content={
                                    <>
                                        <InputController
                                            style={{
                                                borderRadius: '8px',
                                            }}
                                            type="number"
                                            min={0}
                                            {...{
                                                control,
                                                name: 'unitNumber',
                                                placeholder: trans('teams.add.number'),
                                            }}
                                        />
                                        <MultiSelectController
                                            style={{
                                                borderRadius: '8px',
                                                maxWidth: '50%',
                                            }}
                                            {...{
                                                control,
                                                name: 'unit',
                                                options: arrayToSelectOptions({
                                                    array: UnitsSessions,
                                                }),
                                                transSuffix: 'form.subGoal.',
                                                menuPlacement: 'bottom',
                                            }}
                                        />
                                    </>
                                }
                            />
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.sessionsNeeded')}
                                content={
                                    <InputController
                                        type="number"
                                        min={0}
                                        max={6}
                                        {...{
                                            control,
                                            name: 'sessionsNeeded',
                                            placeholder: trans('teams.add.sessionsNumber'),
                                        }}
                                    />
                                }
                            />
                        </Theme.TwoInputsWrapper>
                    </Theme.FullWidthInputsWrapperTwoInputs>
                    <Theme.FullWidthInputsWrapperTwoInputs>
                        <Theme.InputsWrapper>
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.missionTwo')}
                                content={
                                    <InputController
                                        type="text"
                                        {...{
                                            control,
                                            name: 'missionTwo',
                                            placeholder: trans('teams.add.missionTwo'),
                                        }}
                                    />
                                }
                            />
                        </Theme.InputsWrapper>
                        <Theme.TwoInputsWrapper>
                            <FormRow
                                style={{
                                    fontSize: '14px',
                                    color: '#777777',
                                    display: 'block',
                                }}
                                contentStyle={{
                                    display: 'flex',
                                    gap: '4px',
                                }}
                                title={trans('label.volume.targeted')}
                                content={
                                    <>
                                        <InputController
                                            style={{
                                                borderRadius: '8px',
                                            }}
                                            type="number"
                                            min={0}
                                            {...{
                                                control,
                                                name: 'unitNumberTwo',
                                                placeholder: trans('teams.add.unitNumber'),
                                            }}
                                        />
                                        <MultiSelectController
                                            style={{
                                                borderRadius: '8px',
                                                maxWidth: '50%',
                                            }}
                                            {...{
                                                control,
                                                name: 'unitTwo',
                                                options: arrayToSelectOptions({
                                                    array: UnitsSessions,
                                                }),
                                                transSuffix: 'form.subGoal.',
                                                menuPlacement: 'bottom',
                                            }}
                                        />
                                    </>
                                }
                            />
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.sessionsNeeded')}
                                content={
                                    <InputController
                                        type="number"
                                        min={0}
                                        max={6}
                                        {...{
                                            control,
                                            name: 'sessionsNeededTwo',
                                            placeholder: trans('teams.add.sessionsNumber'),
                                        }}
                                    />
                                }
                            />
                        </Theme.TwoInputsWrapper>
                    </Theme.FullWidthInputsWrapperTwoInputs>
                    <Theme.FullWidthInputsWrapperTwoInputs>
                        <Theme.InputsWrapper>
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.missionThree')}
                                content={
                                    <InputController
                                        type="text"
                                        {...{
                                            control,
                                            name: 'missionThree',
                                            placeholder: trans('teams.add.missionThree'),
                                        }}
                                    />
                                }
                            />
                        </Theme.InputsWrapper>
                        <Theme.TwoInputsWrapper>
                            <FormRow
                                style={{
                                    fontSize: '14px',
                                    color: '#777777',
                                    display: 'block',
                                }}
                                contentStyle={{
                                    display: 'flex',
                                    gap: '4px',
                                }}
                                title={trans('label.volume.targeted')}
                                content={
                                    <>
                                        <InputController
                                            style={{
                                                borderRadius: '8px',
                                            }}
                                            type="number"
                                            min={0}
                                            {...{
                                                control,
                                                name: 'unitNumberThree',
                                                placeholder: trans('teams.add.unitNumber'),
                                            }}
                                        />
                                        <MultiSelectController
                                            style={{
                                                borderRadius: '8px',
                                                maxWidth: '50%',
                                            }}
                                            {...{
                                                control,
                                                name: 'unitThree',
                                                options: arrayToSelectOptions({
                                                    array: UnitsSessions,
                                                }),
                                                transSuffix: 'form.subGoal.',
                                                menuPlacement: 'bottom',
                                            }}
                                        />
                                    </>
                                }
                            />
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.sessionsNeeded')}
                                content={
                                    <InputController
                                        type="number"
                                        min={0}
                                        max={6}
                                        {...{
                                            control,
                                            name: 'sessionsNeededThree',
                                            placeholder: trans('teams.add.sessionsNumber'),
                                        }}
                                    />
                                }
                            />
                        </Theme.TwoInputsWrapper>
                    </Theme.FullWidthInputsWrapperTwoInputs>
                    <Theme.FullWidthInputsWrapperTwoInputs>
                        <Theme.InputsWrapper>
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.missionFour')}
                                content={
                                    <InputController
                                        type="text"
                                        {...{
                                            control,
                                            name: 'missionFour',
                                            placeholder: trans('teams.add.missionFour'),
                                        }}
                                    />
                                }
                            />
                        </Theme.InputsWrapper>
                        <Theme.TwoInputsWrapper>
                            <FormRow
                                style={{
                                    fontSize: '14px',
                                    color: '#777777',
                                    display: 'block',
                                }}
                                contentStyle={{
                                    display: 'flex',
                                    gap: '4px',
                                }}
                                title={trans('label.volume.targeted')}
                                content={
                                    <>
                                        <InputController
                                            style={{
                                                borderRadius: '8px',
                                            }}
                                            type="number"
                                            min={0}
                                            {...{
                                                control,
                                                name: 'unitNumberFour',
                                                placeholder: trans('teams.add.unitNumber'),
                                            }}
                                        />
                                        <MultiSelectController
                                            style={{
                                                borderRadius: '8px',
                                                maxWidth: '50%',
                                            }}
                                            {...{
                                                control,
                                                name: 'unitFour',
                                                options: arrayToSelectOptions({
                                                    array: UnitsSessions,
                                                }),
                                                transSuffix: 'form.subGoal.',
                                                menuPlacement: 'bottom',
                                            }}
                                        />
                                    </>
                                }
                            />
                            <FormRow
                                style={{ fontSize: '14px', display: 'block', color: '#7d7d7d' }}
                                title={trans('teams.add.sessionsNeeded')}
                                content={
                                    <InputController
                                        type="number"
                                        min={0}
                                        max={6}
                                        {...{
                                            control,
                                            name: 'sessionsNeededFour',
                                            placeholder: trans('teams.add.sessionsNumber'),
                                        }}
                                    />
                                }
                            />
                        </Theme.TwoInputsWrapper>
                    </Theme.FullWidthInputsWrapperTwoInputs>
                    <Theme.InputMultiElemintsWrapperRight>
                        <SharedButton onClick={handleSubmit(handleSave)}>
                            <>{isSubmittingForm ? <SaveLoaderButton /> : trans('button.save')}</>
                        </SharedButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </>
    );
};
