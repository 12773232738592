import React, { useMemo, useState } from 'react';
import { useForm, FormProvider, Controller, SubmitHandler } from 'react-hook-form';
import { SharedModal } from '../../sharedModal';
import { useLocales } from 'hooks/locales';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls } from 'store/controlsSlice';
import * as Theme from './Theme';
import { router } from 'routers';
import { FormRow } from 'components/modal-windows/FormRow';
import { MultiSelectController } from 'components/multi-selection';
import { addMonths, arrayToSelectOptions } from 'libs/helpers';
import { useGetAthletes } from 'hooks';
import { SubscriptionPeriod } from 'libs/enums';
import { useFetchAthleteDetailsById } from 'services/hooks/players/useFetchAthleteDetailsById';
import { yupResolver } from '@hookform/resolvers/yup';
import { SharedButton } from 'components/sharedButton';
import { InputDateController } from 'components/inputDate';
import { useCreateContractAdminStratorsSchema } from 'schemas/administrator/useCreateContractAdminStratorsSchema';
import {
    useCreateAdminStratorsContract,
    useEditAdminStratorsContract,
} from 'services/hooks/administrator/useCreateOrEditAdminStratorsContract';

interface EditContactAdminStratorModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface EditAdminStratorContactForm {
    joinDate: Date;
    contract: any;
    expirationDate: Date;
}

export const EditAdminStratorContract: React.FC<EditContactAdminStratorModalProps> = ({
    isOpen,
    onClose,
}) => {
    const { trans } = useLocales();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { modalContent } = useSelector(selectControls);
    const {
        params: { id },
    } = router.getState();

    const { data: athlete } = useGetAthletes<any>({
        id: id || '',
        idType: 'athlete',
        dependents: [modalContent],
    });
    const { data, refetch } = useFetchAthleteDetailsById(id || '');
    const schema = useCreateContractAdminStratorsSchema();
    const methods = useForm<EditAdminStratorContactForm>({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const { control, reset } = methods;

    const { mutate: editAdminStratorsContract } = useEditAdminStratorsContract(id);
    const { mutate: createAdminStratorsContract } = useCreateAdminStratorsContract(id);

    const handleSave: SubmitHandler<EditAdminStratorContactForm> = async (data) => {
        setIsLoading(true);
        const contactData = {
            joinDate: data.joinDate,
            contract: data.contract.value || data.contract,
            expirationDate: data.expirationDate,
        };

        // try {
        //     // Decide whether to call create or update based on existing data
        //     const mutation = athlete?.emergencyContact ? updatePlayerContact : createPlayerContact;
        //     // await mutation(contactData);

        //     dispatch(
        //         setModalContent({
        //             modalContent: {
        //                 type: 'success',
        //                 title: trans('form.success'),
        //                 subtitle: athlete?.emergencyContact
        //                     ? trans('player.contactDataUpdatedSuccess')
        //                     : trans('player.contactDataCreatedSuccess'),
        //             },
        //         }),
        //     );
        //     refetch();
        //     onClose();
        // } catch (error: any) {
        //     dispatch(
        //         setModalContent({
        //             modalContent: {
        //                 type: 'warning',
        //                 title: trans('form.warning'),
        //                 subtitle: error.message,
        //             },
        //         }),
        //     );
        // } finally {
        //     setIsLoading(false);
        // }
    };

    return (
        <FormProvider {...methods}>
            <SharedModal
                isOpen={isOpen}
                onRequestClose={onClose}
                title={trans('form.editAthleteProfile.contract')}
            >
                <Theme.LineHR />
                <Theme.Body>
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('player.Edit.joinDate')}
                            content={
                                <InputDateController control={methods.control} name="joinDate" />
                            }
                        />
                    </Theme.InputsWrapper>
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('label.contractType')}
                            content={
                                <MultiSelectController
                                    control={methods.control}
                                    name="contract"
                                    options={arrayToSelectOptions({ array: SubscriptionPeriod })}
                                    transSuffix="form.subscriptionManagement.periodSubscription."
                                />
                            }
                        />
                    </Theme.InputsWrapper>
                    <Theme.InputsWrapper>
                        <FormRow
                            style={{ display: 'block', color: '#7d7d7d' }}
                            title={trans('athlete.personal.expirationDate')}
                            content={
                                <InputDateController
                                    control={methods.control}
                                    name="expirationDate"
                                    maxDate={addMonths(new Date(), 100)}
                                    minDate={new Date()}
                                />
                            }
                        />
                    </Theme.InputsWrapper>

                    <Theme.LineHR />
                    <Theme.InputMultiElemintsWrapperRight>
                        <SharedButton
                            variant="secondary"
                            onClick={methods.handleSubmit(handleSave)}
                        >
                            <img
                                src="/assets/icons/add-icon-colored.svg"
                                height={20}
                                width={20}
                                alt="Save Icon"
                            />
                            {trans('player.edit.Contract')}
                        </SharedButton>
                    </Theme.InputMultiElemintsWrapperRight>
                </Theme.Body>
            </SharedModal>
        </FormProvider>
    );
};
