import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface BankDetails {
    id: string;
    iban: string;
    bank: string;
    accountHolder: string;
}

interface ClothesDetails {
    id: string;
    tShirtSize: string;
    shoeSize: string;
    accountHolder: string;
    pantSize: string;
    driFitSize: string;
}

interface EmergencyDetails {
    id: string;
    name: string;
    relationship: string;
    phoneNumber: string;
    nationalNumber: string;
}

interface Teams {
    name: string;
}
interface Subscription {
    id: string;
    status: string;
    subscriptionDate: string;
}

interface AthleteDetailsResponse {
    message: string;
    payload: any;
    status: number;
    extra: Record<string, any>;
}

const fetchAthleteDetailsById = async (athleteId: string): Promise<any> => {
    const response = await api.url(`players/${athleteId}`).get().json<AthleteDetailsResponse>();
    return response.payload;
};
export const useFetchAthleteDetailsById = (
    athleteId: string,
    options?: UseQueryOptions<any, Error>,
): UseQueryResult<any, Error> => {
    return useQuery<any, Error>({
        queryKey: ['athleteDetails', athleteId],
        queryFn: () => fetchAthleteDetailsById(athleteId),
        ...options,
    });
};
