import { memo, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/base/header';
import { Aside } from '../../components/base/aside';
import { Modal } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { selectControls } from 'store';
import { StoreState } from 'libs/types';
import { setAcademy } from 'store/academySlice';

interface DashboardLayoutProps {
    children: ReactNode;
}

const Main = styled.main`
    display: flex;
    min-height: 100vh;
    min-width: 360px;
    width: 100%;
    height: 100%;
`;

interface ContentProps {
    menuOpen: boolean;
    direction: 'ltr' | 'rtl';
}

// Helper function to get margin values based on direction
const getMargin = (
    menuOpen: boolean,
    direction: 'ltr' | 'rtl',
    values: { open: string; closed: string },
) =>
    direction === 'ltr'
        ? `80px 0 0 ${menuOpen ? values.open : values.closed}`
        : `80px ${menuOpen ? values.open : values.closed} 0 0`;

const getContentStyles = (menuOpen: boolean, direction: 'ltr' | 'rtl') => ({
    display: 'flex',
    flex: 1,
    height: 'auto',
    padding: '0px 20px',
    width: menuOpen ? '85%' : '100%',
    margin: getMargin(menuOpen, direction, { open: '10%', closed: '3%' }),

    '@media (max-width: 1600px)': {
        width: menuOpen ? '82%' : '100%',
        margin: getMargin(menuOpen, direction, { open: '13%', closed: '3%' }),
    },
    '@media (max-width: 1400px)': {
        width: menuOpen ? '82%' : '100%',
        margin: getMargin(menuOpen, direction, { open: '14%', closed: '7%' }),
    },
    '@media (max-width: 1200px)': {
        width: menuOpen ? '82%' : '100%',
        margin: getMargin(menuOpen, direction, { open: '17%', closed: '7%' }),
    },
    '@media (max-width: 1024px)': {
        width: menuOpen ? '82%' : '100%',
        margin: getMargin(menuOpen, direction, { open: '20%', closed: '9%' }),
    },
    '@media (max-width: 800xp)': {
        width: menuOpen ? '82%' : '100%',
        margin: getMargin(menuOpen, direction, { open: '30%', closed: '11%' }),
    },
    '@media (max-width: 768px)': {
        width: menuOpen ? '82%' : '100%',
        margin: getMargin(menuOpen, direction, { open: '30%', closed: '11%' }),
    },
    '@media (max-width: 600px)': {
        width: '100%',
        margin: getMargin(menuOpen, direction, { open: '12%', closed: '12%' }),
    },
    '@media (max-width: 480px)': {
        width: '100%',
        margin: getMargin(menuOpen, direction, { open: '12%', closed: '12%' }),
    },
    '@media (max-width: 360px)': {
        width: '100%',
        margin: getMargin(menuOpen, direction, { open: '12%', closed: '12%' }),
    },
});

export const Content = styled.div.attrs<ContentProps>((props) => ({
    className: 'relative flex flex-col flex-1',
    dir: props.direction || 'ltr',
}))<ContentProps>`
    ${({ menuOpen, direction }) => getContentStyles(menuOpen, direction)}
`;

export const DashboardLayout = memo(({ children }: DashboardLayoutProps) => {
    const { modalContent } = useSelector(selectControls);
    const dispatch = useDispatch();
    const user = useSelector((state: StoreState) => state.auth?.entities);
    const { isRTL } = useSelector<any>((state) => state?.locales) as any;
    const [menuOpen, setMenuOpen] = useState(() => {
        return localStorage.getItem('menuOpen') === 'true';
    });

    const toggleMenu = () => {
        setMenuOpen((prev) => {
            const newState = !prev;
            localStorage.setItem('menuOpen', newState.toString());
            return newState;
        });
    };

    useEffect(() => {
        if (user?.academy) dispatch(setAcademy({ academy: user.academy }));
    }, [user, dispatch]);

    return (
        <Main>
            {modalContent.type !== 'none' && <Modal />}
            <Aside toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <Header menuOpen={menuOpen} />
            <Content direction={isRTL ? 'rtl' : 'ltr'} menuOpen={menuOpen}>
                {children}
            </Content>
        </Main>
    );
});
