import React from 'react';
import * as Theme from './Theme';
import NotificationMenu from './notifcationMenu/NotificationMenu';
import { ProfileMenu } from './profileMenu/profileMenu';
import { Breadcrumbs } from '../breadCrumps';
import styled from 'styled-components';

interface HeaderProps {
    menuOpen?: boolean;
}

const ResponsiveCBody = styled(Theme.CBody)<{ menuOpen?: boolean }>`
    margin-inline-start: ${({ menuOpen }) => (menuOpen ? '12%' : '3%')};
    transition: margin 0.3s ease-in-out;
    @media (max-width: 1500px) {
        margin-inline-start: ${({ menuOpen }) => (menuOpen ? '14.5%' : '3%')};
    }
    @media (max-width: 1200px) {
        margin-inline-start: ${({ menuOpen }) => (menuOpen ? '18%' : '3%')};
    }
    @media (max-width: 1024px) {
        margin-inline-start: ${({ menuOpen }) => (menuOpen ? '22%' : '5%')};
    }
    @media (max-width: 768px) {
        display: none !important;
        margin-inline-start: ${({ menuOpen }) => (menuOpen ? '6%' : '6%')};
    }
    @media (max-width: 600px) {
        display: none !important;
        margin-inline-start: ${({ menuOpen }) => (menuOpen ? '2%' : '2%')};
    }
    @media (max-width: 480px) {
        display: none !important;
        margin-inline-start: ${({ menuOpen }) => (menuOpen ? '5%' : '1%')};
    }
`;

export const Header: React.FC<HeaderProps> = ({ menuOpen }) => {
    console.log(menuOpen);

    return (
        <Theme.Body>
            <ResponsiveCBody menuOpen={menuOpen}>
                <Breadcrumbs />
            </ResponsiveCBody>
            <Theme.UserBox>
                <NotificationMenu />
                <ProfileMenu />
            </Theme.UserBox>
        </Theme.Body>
    );
};
