import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SharedModal, MultiSelectController, SelectionCardController } from 'components';
import * as Theme from './theme';
import { Divider } from 'components/modal-windows';
import { useLocales } from 'hooks/locales';
import { useAddSportClubProfile } from 'services/hooks';
import { useSelector } from 'react-redux';
import { selectAcademy } from 'store';
import { useClubList } from 'services/hooks/clubProfile/useClubList';
import { toast } from 'react-toastify';
import { useFetchManagersTable } from 'services/hooks/manager/useFetchManagersTable';
import { SharedButton } from 'components/sharedButton';
import { useSportOptions } from 'libs/utils/sportOptions';
import { SaveLoaderButton } from 'components/saveLoaderButton';

interface AddSportFormValues {
    sport: string;
    sportProfileManager: string[];
    technicalDirector: string[];
}
interface ClubListProps {
    clubs: any;
}

export const AddNewSportModal: React.FC<ClubListProps> = ({ clubs }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { trans } = useLocales();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const { academy } = useSelector(selectAcademy);
    const sportOptions = useSportOptions();
    console.log(sportOptions);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<AddSportFormValues>({
        defaultValues: {
            sport: '',
            sportProfileManager: [],
            technicalDirector: [],
        },
    });

    const { refetch } = useClubList(academy.id);
    const { mutate: addSportClubProfile, isPending: isLoading } = useAddSportClubProfile({
        onSuccess: (response: any) => {
            console.log('Sport club profile added successfully', response);
            refetch();
            setIsOpen(false);
            reset();
        },
        onError: (error: any) => {
            console.error('Error adding sport club profile', error);

            toast.error(trans('error.message'));
        },
    });
    const {
        data: managerData,
        isLoading: isManagerLoading,
        error: managerError,
    } = useFetchManagersTable(academy.id, page, limit);
    console.log(managerData);
    const handleAddSport = (data: AddSportFormValues) => {
        addSportClubProfile({
            academyId: academy?.id,
            ...data,
        });
    };
    return (
        <>
            <Theme.ButtonPrimary onClick={() => setIsOpen(true)}>
                {trans('clubList.addNewClub')}
            </Theme.ButtonPrimary>

            <SharedModal
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                    reset();
                }}
                title={trans('clubList.addNewClub')}
                footerContent={
                    <SharedButton onClick={handleSubmit(handleAddSport)}>
                        {isLoading ? <SaveLoaderButton /> : trans('form.addSportProfileType.add')}
                    </SharedButton>
                }
            >
                <SelectionCardController control={control} name="sport" options={sportOptions} />
                <Divider />
                <form onSubmit={handleSubmit(handleAddSport)}>
                    <Theme.Form>
                        <Theme.Field>
                            <Theme.Label>{trans('addNewSport.sportProfileManager')}</Theme.Label>
                            <MultiSelectController
                                control={control}
                                name="sportProfileManager"
                                options={
                                    managerData?.data?.map((person: any) => ({
                                        label: `${person.firstName} ${person.lastName}`,
                                        value: person.id,
                                    })) || []
                                }
                            />
                            {errors.sportProfileManager && (
                                <Theme.ErrorText>
                                    {errors.sportProfileManager.message}
                                </Theme.ErrorText>
                            )}
                        </Theme.Field>

                        <Theme.Field>
                            <Theme.Label>{trans('addNewSport.technicalDirector')}</Theme.Label>
                            <MultiSelectController
                                control={control}
                                name="technicalDirector"
                                options={
                                    managerData?.data?.map((person: any) => ({
                                        label: `${person.firstName} ${person.lastName}`,
                                        value: person.id,
                                    })) || []
                                }
                            />

                            {errors.technicalDirector && (
                                <Theme.ErrorText>
                                    {errors.technicalDirector.message}
                                </Theme.ErrorText>
                            )}
                        </Theme.Field>
                    </Theme.Form>
                </form>
            </SharedModal>
        </>
    );
};
