import React, { useState } from 'react';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { SubGoalModal } from 'components/subGoalModal';
import { YearGoalModal } from 'components/yearGoalModal';
import { GoalStatusBoard } from './goalsStatus';

interface Column {
    key: string;
    label: string;
    width?: string;
    sortable?: boolean;
}
export const Vision: React.FC<any> = (teamSession) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const { trans } = useLocales();
    const [columns, setColumns] = useState<Column[]>([]);
    console.log(teamSession.data);

    const redirect = () => {
        console.log('redirect');
        // router.push(`/teams/${sportId}/sub-goals`);
    };
    return (
        <Theme.Body>
            <SubGoalModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <YearGoalModal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} />
            <Theme.YearGoalComponent>
                <Theme.ButtonWrapper>
                    <Theme.ButtonRedirect type="button" onClick={() => redirect()}>
                        <Theme.ButtonIcon
                            src="/assets/icons/next-icon.svg"
                            height={16}
                            width={16}
                            alt="Add Icon"
                        />
                        {trans('2024')}
                    </Theme.ButtonRedirect>
                </Theme.ButtonWrapper>
                <Theme.GoalTitleWrapper>
                    <Theme.SmallTextSpan>Goal of the Year</Theme.SmallTextSpan>
                    <Theme.GoaloftheYearText>
                        Win at least 40 matches
                        <Theme.ButtonOpenModal
                            type="button"
                            onClick={() => setIsEditModalOpen(true)}
                        >
                            <img
                                src="/assets/icons/edit.svg"
                                height={16}
                                width={16}
                                alt="editGoal"
                            />
                        </Theme.ButtonOpenModal>
                    </Theme.GoaloftheYearText>
                    <Theme.SmallTextSpan>{`{January,2025 - December,2025}`}</Theme.SmallTextSpan>
                </Theme.GoalTitleWrapper>
                <Theme.EmptyDiv></Theme.EmptyDiv>
            </Theme.YearGoalComponent>
            <Theme.StatusBar>
                <Theme.UsersNumberTable>{trans('Sub-goals(6)')}</Theme.UsersNumberTable>
                <Theme.ButtonsWrapper type="button" onClick={() => setIsModalOpen(true)}>
                    <Theme.ButtonIcon
                        src="/assets/icons/add-icon.svg"
                        height={16}
                        width={16}
                        alt="Add Icon"
                    />
                    {trans('add.sub.goal')}
                </Theme.ButtonsWrapper>
            </Theme.StatusBar>
            <GoalStatusBoard data={teamSession.data || []} />
        </Theme.Body>
    );
};
